<template>
  <div>
    <div>
      <util-date-range-compare-picker />
    </div>
    <div class="cui__utils__heading">
      <strong>Cards Sent Trend</strong>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="position-relative">
          <vue-chartist
            class="chartist-animated height-600 mt-4"
            type="Line"
            :data="animationData"
            :options="animatonOptions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangeComparePicker from './DateRangeComparePickerHelper'
import VueChartist from 'v-chartist'
import ChartistTooltip from 'chartist-plugin-tooltips-updated'
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  name: 'CardsSentTrend',
  components: {
    'vue-chartist': VueChartist,
    'util-date-range-compare-picker': DateRangeComparePicker,
  },
  data: function () {
    const animationData = {
      labels: [],
      series: [],
    }

    const animatonOptions = {
      axisX: {
        showGrid: false,
        labelInterpolationFnc(value, index) {
          return index % 2 !== 0 ? !1 : value
        },
        onlyInteger: true,
      },
      axisY: {
        onlyInteger: true,
      },
      plugins: [ChartistTooltip({ anchorToPoint: false, appendToBody: true, seriesName: false })],
    }

    return {
      animationData,
      animatonOptions,
    }
  },
  computed: {
    ...mapState('admin', [
      'orderedProductsDetails',
      'orderedProductsDetails2',
      'globalTrendDateRange',
      'globalTrendDateRange2',
      'globalClientSelected',
    ]),
  },
  methods: {
    ...mapActions('admin', [
      'fetchOrderedProductDetails',
    ]),
    ...mapMutations('admin', [
      'startLoading',
      'stopLoading',
    ]),

    triggerUpdateChart() {
      this.startLoading()
      let range1Orders = []
      let range2Orders = []

      Promise.all([
        this.fetchOrderedProductDetails({
          saveToStateObj: 'setOrderedProductsDetails',
          selectFilter: '(count,total,hits.(data.(creation_date,product_items.(product_id,quantity))))',
          fromDate: this.globalTrendDateRange.fromDate,
          toDate: this.globalTrendDateRange.toDate,
        }),
        this.fetchOrderedProductDetails({
          saveToStateObj: 'setOrderedProductsDetails2',
          selectFilter: '(count,total,hits.(data.(creation_date,product_items.(product_id,quantity))))',
          fromDate: this.globalTrendDateRange2.fromDate,
          toDate: this.globalTrendDateRange2.toDate,
          tempBatchObj2: true,
        }),
      ])
        .then(() => { range1Orders = this.getOrderedProductsBydate(this.orderedProductsDetails, this.globalTrendDateRange) })
        .then(() => { range2Orders = this.getOrderedProductsBydate(this.orderedProductsDetails2, this.globalTrendDateRange2) })
        .then(() => this.updateChart(range1Orders, range2Orders))
        .then(() => this.stopLoading())
    },

    updateChart(range1Orders, range2Orders) {
      const orderDate = []
      const range1Qty = []
      range1Orders.forEach(range1Order => {
        orderDate.push(range1Order.orderDate)
        range1Qty.push(range1Order.qty)
      })
      const range2Qty = []
      range2Orders.forEach(range2Order => range2Qty.push(range2Order.qty))

      this.animationData = {
        series: [
          range1Qty,
          range2Qty,
        ],
      }
    },

    getOrderedProductsBydate(orderedDetails, dateRange) {
      const orderedProducts = orderedDetails || []
      const groupOrders = []
      /* for (let i = 1; i < 32; i++) {
        groupOrders.push({ orderDate: i, qty: 0 })
      }

      // Grouping orders by date and adding the ordered quantity to get total
      orderedProducts.reduce((res, value) => {
        const creationDate = (value.data.creation_date).slice(8, 10)
        const prodItems = value.data.product_items || []
        prodItems.forEach((prodItem) => {
          res[creationDate - 1].qty += prodItem.quantity
        })
        return res
      }, groupOrders) */

      // Generate Initial value for the chart
      const initialValue = [];
      let day = dateRange.fromDate;
      while (day <= dateRange.toDate) {
        const fDay = day.format('YYYY-MM-DD')
        initialValue[fDay] = { orderDate: fDay, qty: 0 }
        groupOrders.push(initialValue[fDay])
        day = day.clone().add(1, 'days');
      }

      // Group orders by date
      orderedProducts.reduce((res, value) => {
        const creationDate = this.formatUTCtoLocal(value.data.creation_date)
        const prodItems = value.data.product_items || []
        prodItems.forEach((prodItem) => {
          if (!res[creationDate]) { // Wont loop inside this since we have initial value passed
            res[creationDate] = { orderDate: creationDate, qty: 0 }
            groupOrders.push(res[creationDate])
          }
          res[creationDate].qty += prodItem.quantity
        })
        return res
      }, initialValue)

      return groupOrders
    },

    formatUTCtoLocal(utc) {
      return (new Date(utc))?.toLocaleDateString()
    },
  },

  mounted() {
    this.triggerUpdateChart()
  },

  watch: {
    globalClientSelected() {
      this.triggerUpdateChart()
    }
  },
}
</script>
